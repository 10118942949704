{#if isShowForm}
  <fieldset style="background: white; padding: 10px;">
    {#if invalid}
      <FormErrors on:close="closeErrors()" errors={formErrors} />
    {/if}
    <div class="form-group">
      <label>{ t('name') }<sup>*</sup></label>
      <input on:keydown="handleInputKeyDown(event)" type="text" class="form-control" bind:value=form.name />
      <small class="form-text text-muted">
        { t('personal_data_options.description_name') }
      </small>
    </div>

    <div class="actions">
      <button type="button" class="btn btn-primary" on:click="submit()">{ t('save') }</button>
      <button type="button" class="btn btn-primary" on:click="hideForm()">{ t('cancel') }</button>
    </div>
  </fieldset>
{:else}
  <button type="button" class="btn btn-primary" on:click="showForm()">{ t('add_option') }</button>
{/if}

<script>
  import { apiFetch as fetch } from '../../../services/apiFetch';
  import _ from 'lodash';
  import $ from 'jquery';

  const INITIAL_STATE = {
    isShowForm: false,
    form: {
      name: '',
      pdc_id: ''
    },
    formErrors: {},
    invalid: false
  };

  export default {
    data() {
      return _.cloneDeep(INITIAL_STATE)
    },
    components: {
      FormErrors: '../../common/FormErrors',
    },
    helpers: {
      t(k) {
        return window.I18n.t(k)
      },
    },
    methods: {
      showForm() {
        this.set({ isShowForm: true })
      },
      closeErrors() {
        this.set({ invalid: false, formErrors: {} })
      },
      hideForm() {
        this.set({ isShowForm: false })
      },
      handleInputKeyDown(event) {
        if(event.code === 'Enter') {
          event.preventDefault()
          this.submit()
        }
      },
      populateInputWith(json) {
        const { saveTo } = this.get()
        const newOption = document.createElement('option')
        newOption.innerText = json.name
        newOption.setAttribute('value', json.id)
        newOption.setAttribute('selected', 'selected')
        saveTo.appendChild(newOption)
        $(saveTo).trigger('chosen:updated')
        this.set(_.cloneDeep(INITIAL_STATE))
      },
      submit() {
        const { form } = this.get()
        form.pdc_id = this.get().pdcId
        fetch('/api/v1/personal_data_options', { method: 'POST', body: form })
          .then(resp => resp.json())
          .then((json) => {
            if(json.invalid) {
              return this.set({ invalid: true, formErrors: json.errors })
            }
            this.populateInputWith(json)
          })
      }
    }
  }
</script>
