import AssetForm from './nested_forms/AssetForm.html'
import SupplierForm from './nested_forms/SupplierForm.html'
import RegistryForm from './nested_forms/RegistryForm.html'
import RequirementForm from './nested_forms/RequirementForm.html'
import SectorForm from './nested_forms/SectorForm.html'
import PersonalDataOptionForm from './nested_forms/PersonalDataOptionForm.html'
import RetentionTimeForm from './nested_forms/RetentionTimeForm.html'
import DataSubjectCategoryForm from './nested_forms/DataSubjectCategoryForm.html'
import AccessIndividualForm from './nested_forms/AccessIndividualForm.html'

function setupComponent(Klass, target, saveTo, dataMapping = []) {
  console.log("setting up component")
  if (target && saveTo) {
    const data = dataMapping.reduce((acc, k) => {
      acc[k] = JSON.parse(target.dataset[k])
      return acc
    }, {})

    // eslint-disable-next-line no-new
    new Klass({ target, data: { ...data, saveTo } })
  }
}

setupComponent(
  AssetForm,
  document.getElementById('template-asset-form'),
  document.getElementById('asset_ids'),
  ['dataClassifications', 'suppliers', 'countries'],
)
setupComponent(
  SupplierForm,
  document.getElementById('template-supplier-form'),
  document.getElementById('supplier_ids'),
  ['processorAgreementChecklistItems', 'dataClassifications', 'countries'],
)
setupComponent(
  RegistryForm,
  document.getElementById('template-registry-form'),
  document.getElementById('registry_ids'),
)
setupComponent(
  RequirementForm,
  document.getElementById('template-requirement-form'),
  document.getElementById('requirement_ids'),
)
setupComponent(
  SectorForm,
  document.getElementById('template-sector-form'),
  document.getElementById('sector_id'),
)
setupComponent(
  DataSubjectCategoryForm,
  document.getElementById('template-data_subject_category-form'),
  document.getElementById('data-subject-category-selection'),
  ["companyId"]
)
setupComponent(
  AccessIndividualForm,
  document.getElementById('template-access_individual-form'),
  document.getElementById('data-access_individual-selection'),
  ["companyId"]
)

var forms = document.getElementsByClassName("template-personal_data_option-form")
var ids = document.getElementsByClassName("registry-entries__form-fields-list")
var i;
for(i = 0; i < forms.length; i++) {
    if(forms[i].dataset["pdcId"] != undefined) {
        setupComponent(
            PersonalDataOptionForm,
            forms[i],
            ids[i],
            ["pdcId"],
        )
    }
}

forms = document.getElementsByClassName("template-retention_time-form")
ids = document.getElementsByClassName("registry-entries__form-retentiontime-list")
console.log("retention time form")
for(i = 0; i < forms.length; i++) {
    console.log(forms[i].dataset)
    if(forms[i].dataset["pdcId"] != undefined){
        setupComponent(
            RetentionTimeForm,
            forms[i],
            ids[i],
            ["pdcId", "units"],
        )
    }
}
