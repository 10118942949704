<table class="table table-bordered" style="background: white;">
  <thead>
    <tr>
      <th></th>
      <th>
        <div class="form-group">
          <label class="col-sm-2 control-label" for="version_left">{ t('version') + ':' }</label>
          <div class="col-sm-10">
            <select
              value={versionLeft && versionLeft.updated_at}
              on:change="updateVersion('versionLeft', this.value)"
              class="form-control"
              name="version_left"
              id="version_left"
            >
              {#each versions as version}
                {#if versionRight && new Date(version.updated_at) < new Date(versionRight.updated_at) }
                  <option value={version.updated_at}>{ formatDate(version.updated_at) }</option>
                {/if}
              {/each}
            </select>
          </div>
        </div>
      </th>
      <th>
        <label class="col-sm-2 control-label" for="version_right">{ t('version') + ':' }</label>
        <div class="col-sm-10">
          <select
            value={versionRight && versionRight.updated_at}
            on:change="updateVersion('versionRight', this.value)"
            class="form-control"
            name="version_right"
            id="version_right"
          >
            {#each versions as version}
              <option value={version.updated_at}>{ formatDate(version.updated_at) }</option>
            {/each}
          </select>
        </div>
      </th>
    </tr>
  </thead>

  {#if versionLeft && versionRight }
    <CompareVersions {versionLeft} {versionRight} />
  {/if}
</table>

<script>
  import _ from "lodash";
  import { DateTime } from 'luxon';

  export default {
    immutable: true,

    oncreate() {
      const { versions } = this.get();
      const [versionLeft, versionRight] = _.takeRight(versions, 2);
      this.set({ versionLeft, versionRight });
    },

    helpers: {
      formatDate(date) {
        const dt = DateTime.fromJSDate(new Date(date));
        return dt.toFormat("dd-MM-yyyy HH:mm");
      },
      t(k) {
        return window.I18n.t(k);
      },
    },

    methods: {
      updateVersion(type, value) {
        const { versions } = this.get();
        const version = _.find(versions, (v) => v.updated_at === value);
        this.set({ [type]: version });
      }
    },

    components: {
      CompareVersions: './CompareVersions.html',
    }
  }
</script>
