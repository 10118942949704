<td><strong>{ label }</strong></td>
{#if leftValue }
  <td><CompareDiff {diff} reverse /></td>
{:else}
  <td class="no-value">{ t('no_value') }</td>
{/if}
{#if rightValue }
  <td><CompareDiff {diff} /></td>
{:else}
  <td class="no-value">{ t('no_value') }</td>
{/if}

<script>
  import { diffWords } from 'diff';

  export default {
    components: {
      CompareDiff: './CompareDiff',
    },
    helpers: {
      t(k) {
        return window.I18n.t(k);
      },
    },
    computed: {
      rightValue: ({ renderProperty, right }) => right && renderProperty(right) || '',
      leftValue:  ({ renderProperty, left }) => left && renderProperty(left) || '',
      diff: ({ leftValue, rightValue }) => diffWords(leftValue, rightValue),
    }
  }
</script>

<style>
  .no-value {
    background: gray;
    color: white;
  }
</style>
