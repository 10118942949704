{#if isShowForm}
  <fieldset style="background: white; padding: 10px;">
    {#if invalid}
      <FormErrors on:close="closeErrors()" errors={formErrors} />
    {/if}
    <div class="form-group">
      <label>{ t('retention_time') }<sup>*</sup></label>
      <input on:keydown="handleInputKeyDown(event)" type="text" class="form-control" bind:value=form.value />
      <label>{ t('unit') }<sup>*</sup></label>
      <select class="gdpr-chosen-select"></select>
      <small class="form-text text-muted">
      </small>
    </div>

    <div class="actions">
      <button type="button" class="btn btn-primary" on:click="submit()">{ t('save') }</button>
      <button type="button" class="btn btn-primary" on:click="hideForm()">{ t('cancel') }</button>
    </div>
  </fieldset>
{:else}
  <button type="button" class="btn btn-primary" on:click="showForm()">{ t('add_retention_time') }</button>
{/if}

<script>
  import { apiFetch as fetch } from '../../../services/apiFetch';
  import _ from 'lodash';
  import $ from 'jquery';
  import 'chosen-js';
  import 'chosen-js/chosen.css';

  const INITIAL_STATE = {
    isShowForm: false,
    form: {
      value: '',
      unit: '',
      pdc_id: ''
    },
    formErrors: {},
    invalid: false
  };

  export default {
    data() {
      return _.cloneDeep(INITIAL_STATE)
    },
    components: {
      FormErrors: '../../common/FormErrors',
    },
    helpers: {
      t(k) {
        return window.I18n.t(k)
      },
    },
    methods: {
      showForm() {
        this.set({ isShowForm: true })

        const units = this.get().units

        const target = $(".template-retention_time-form[data-pdc-id=" + this.get().pdcId + "] > fieldset > .form-group > select")[0]

        for (var i = 0; i < units.length; i++) {
          const newOption = document.createElement('option')
          newOption.innerText = units[i][0]
          newOption.setAttribute('value', units[i][1])
          target.appendChild(newOption)
        }

        const options = {}
        options["no_results_text"] = window.I18n.t("no_results_text");
        options["placeholder_text_single"] = window.I18n.t("placeholder_text_single");
        options["placeholder_text_multiple"] = window.I18n.t("placeholder_text_multiple");
        $(target).chosen(options);
      },
      closeErrors() {
        this.set({ invalid: false, formErrors: {} })
      },
      hideForm() {
        this.set({ isShowForm: false })
      },
      handleInputKeyDown(event) {
        if(event.code === 'Enter') {
          event.preventDefault()
          this.submit()
        }
      },
      populateInputWith(json) {
        const { saveTo } = this.get()
        const newOption = document.createElement('option')
        newOption.innerText = json.value
        newOption.setAttribute('value', json.id)
        newOption.setAttribute('selected', 'selected')
        saveTo.appendChild(newOption)
        $(saveTo).trigger('chosen:updated')
      },
      submit() {
        const { form } = this.get()

        // Once again a bind doesn't seem to work.
        // Doing it the hard way
        const target = $(".template-retention_time-form[data-pdc-id=" + this.get().pdcId + "] > fieldset > .form-group > select")[0]
        form.unit = target.value

        form.pdc_id = this.get().pdcId
        fetch('/api/v1/retention_time', { method: 'POST', body: form })
          .then(resp => resp.json())
          .then((json) => {
            if(json.invalid) {
              return this.set({ invalid: true, formErrors: json.errors })
            }
            this.populateInputWith(json)
          })
      }
    }
  }
</script>
