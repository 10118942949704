{#if isShowForm}
  <fieldset style="background: white; padding: 10px;">
    {#if invalid}
      <FormErrors on:close="closeErrors()" errors={formErrors} />
    {/if}


    <div class="form-group">
      <label>completed</label>
      <select on:keydown="handleInputKeyDown(event)" class="form-control" name="requirement[completed]" bind:value=form.completed>
        <option>{ t('please_select') }</option>
        <option value="yes">yes</option>
        <option value="no">no</option>
      </select>
    </div>

    <div class="form-group">
      <label>description</label>
      <input on:keydown="handleInputKeyDown(event)" type="text" class="form-control" name="requirement[description]" bind:value=form.description />
    </div>

    <div class="form-group">
      <label>help</label>
      <input on:keydown="handleInputKeyDown(event)" type="text" class="form-control" name="requirement[help]" bind:value=form.help />
    </div>

    <div class="form-group">
      <label>Decision documentation</label>
      <input on:keydown="handleInputKeyDown(event)" type="text" class="form-control" name="requirement[decision]" bind:value=form.decision />
    </div>

    <div class="actions">
      <button type="button" class="btn btn-primary" on:click="submit()">{ t('save') }</button>
      <button type="button" class="btn btn-primary" on:click="hideForm()">Cancel</button>
    </div>
  </fieldset>
{:else}
  <button type="button" class="btn btn-primary" on:click="showForm()">{ t('add_requirement') }</button>
{/if}

<script>
  import { apiFetch as fetch } from '../../../services/apiFetch';
  import _ from 'lodash';
  import $ from 'jquery';

  const INITIAL_STATE = {
    isShowForm: false,
    form: {
      decision: '',
      help: '',
      description: ''
    },
    formErrors: {},
    invalid: false
  };

  export default {
    data() {
      return _.cloneDeep(INITIAL_STATE);
    },
    components: {
      FormErrors: '../../common/FormErrors',
    },
    helpers: {
      t(k) {
        return window.I18n.t(k);
      },
    },
    methods: {
      showForm() {
        this.set({ isShowForm: true });
      },
      closeErrors() {
        this.set({ invalid: false, formErrors: {} });
      },
      hideForm() {
        this.set({ isShowForm: false });
      },
      handleInputKeyDown(event) {
        if(event.code === 'Enter') {
          event.preventDefault()
          this.submit();
        }
      },
      populateInputWith(json) {
        const { saveTo } = this.get();
        const newOption = document.createElement('option');
        newOption.innerText = json.description
        newOption.setAttribute('value', json.id)
        newOption.setAttribute('selected', 'selected');
        saveTo.appendChild(newOption);
        $(saveTo).trigger('chosen:updated');
        this.set(_.cloneDeep(INITIAL_STATE));
      },
      submit() {
        const { form } = this.get();
        fetch('/api/v1/requirements', { method: 'POST', body: form })
          .then(resp => resp.json())
          .then((json) => {
            if(json.invalid) {
              return this.set({ invalid: true, formErrors: json.errors })
            }
            this.populateInputWith(json);
          });
      }
    }
  }
</script>
