import $ from 'jquery';
import './ask_expert.scss';

$(document).ready(() => {
    $('.ask-expert__button').click(({ target }) => {
    // eslint-disable-next-line no-alert
    if (window.confirm(window.I18n.t("cost_associated"))) {
    window.location.href = `mailto:${target.dataset.mail}`;
}
});
});
