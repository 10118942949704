import $ from 'jquery';
import 'jquery-ui/ui/widgets/autocomplete';
import 'jquery-ui/themes/base/autocomplete.css';

const UNREGISTERED_FILL = 'unregistered_fill';
const REGISTERED_SELECT = 'registered_select';


$(document).ready(() => {
  const $form = $('.join_controller_form');
  if (!$form.length) { return; }
  const $input = $('#company_search');
  const $flowTypeInput = $('#joint_controller_flow_type');
  const $companySearchGroup = $('#company_search_group');
  const $companyIdInput = $('#joint_controller_company_id');
  const $companyTypeInput = $('#joint_controller_company_type');
  const $newUnregisteredCompanyForm = $('#new_unregistered_company_form');

  function cleanInputsData() {
    $companyIdInput.val(null);
    $companyTypeInput.val(null);
    $input.val(null);
  }

  function fillNewFormFlow() {
    $newUnregisteredCompanyForm.show();
    $companySearchGroup.hide();
    cleanInputsData();
    $flowTypeInput.val(UNREGISTERED_FILL);
  }

  function findExistedFlow() {
    $newUnregisteredCompanyForm.hide();
    $companySearchGroup.show();
    cleanInputsData();
    $flowTypeInput.val(REGISTERED_SELECT);
  }

  if ($flowTypeInput.val() === UNREGISTERED_FILL) {
    fillNewFormFlow();
  } else {
    findExistedFlow();
  }

  $form.on('click', '#create_new_unregistered_company_button', () => fillNewFormFlow());
  $form.on('click', '#search_company_button', () => findExistedFlow());
});
