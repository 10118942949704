{#if isShowForm}
  <fieldset style="background: white; padding: 10px;">
    {#if invalid}
      <FormErrors on:close="closeErrors()" errors={formErrors} />
    {/if}
    <div class="form-group">
      <label>{ t('name') }<sup>*</sup></label>
      <input on:keydown="handleInputKeyDown(event)" type="text" class="form-control" name="new_asset[name]" bind:value=form.name />
      <small class="form-text text-muted">
        { t('app_assets.description_name') }
      </small>
    </div>
    <div class="form-group">
      <label>{ t('classification') }</label>
      <select on:keydown="handleInputKeyDown(event)" class="form-control" name="new_asset[data_classification_id]" bind:value=form.data_classification_id>
        <option>{ t('please_select') }</option>
        {#each dataClassifications as dataClassification}
          <option value={dataClassification.id}>{ dataClassification.name }</option>
        {/each}
      </select>
      <small class="form-text text-muted">
        { t('app_assets.description_classification') }
      </small>
    </div>
    <div class="form-group">
      <label>{ t('description') }</label>
      <input on:keydown="handleInputKeyDown(event)" type="text" class="form-control" name="new_asset[description]" bind:value=form.description />
      <small class="form-text text-muted">
        { t('app_assets.description_description') }
      </small>
    </div>
    <div class="form-group">
      <label>{ t('self_hosted') }</label>
      <select on:keydown="handleInputKeyDown(event)" class="form-control" name="new_asset[self_hosted]" bind:value=form.self_hosted>
        <option>{ t('please_select') }</option>
        <option value="yes">{ t('true') }</option>
        <option value="no">{ t('false') }</option>
      </select>
      <small class="form-text text-muted">
        { t('app_assets.description_self_hosted') }
      </small>
    </div>
    <div class="form-group">
      <label>{ t('supplier') }</label>
      <select on:keydown="handleInputKeyDown(event)" class="form-control" name="new_asset[supplier_id]" bind:value=form.supplier_id>
        <option>{ t('please_select') }</option>
        {#each suppliers as supplier}
          <option value={supplier.id}>{ supplier.name }</option>
        {/each}
      </select>
      <small class="form-text text-muted">
        { t('app_assets.description_supplier') }
      </small>
    </div>
    <div class="form-group">
      <label>{ t('hosting_country') }</label>
      <select on:keydown="handleInputKeyDown(event)" class="form-control" name="new_asset[country_id]" bind:value=form.country_id>
        <option>{ t('please_select') }</option>
        {#each countries as country}
          <option value={country.id}>{ country.name }</option>
        {/each}
      </select>

      <small class="form-text text-muted">
        { t('app_assets_description_hosting_country') }
      </small>
    </div>
    <div class="form-group">
      <label>{ t('mechanism_for_international_data_exhange') }</label>
      <input on:keydown="handleInputKeyDown(event)" type="text" class="form-control" name="new_asset[mechanism_international_data_exchange]" bind:value=form.mechanism_international_data_exchange/>

      <small class="form-text text-muted">
        { t('app_assets.description_international_exchange_mechanism') }
      </small>
    </div>

    <div class="actions">
      <button type="button" class="btn btn-primary" on:click="submit()">{ t('save') }</button>
      <button type="button" class="btn btn-primary" on:click="hideForm()">Cancel</button>
    </div>
  </fieldset>
{:else}
  <button type="button" class="btn btn-primary" on:click="showForm()">{ t('add_asset') }</button>
{/if}

<script>
  import { apiFetch as fetch } from '../../../services/apiFetch';
  import _ from 'lodash';
  import $ from 'jquery';

  const INITIAL_STATE = {
    isShowForm: false,
    form: {
      name: '',
      description: '',
      mechanism_international_data_exchange: ''
    },
    formErrors: {},
    invalid: false
  };

  export default {
    data() {
      return _.cloneDeep(INITIAL_STATE);
    },
    components: {
      FormErrors: '../../common/FormErrors',
    },
    helpers: {
      t(k) {
        return window.I18n.t(k);
      },
    },
    methods: {
      showForm() {
        this.set({ isShowForm: true });
      },
      closeErrors() {
        this.set({ invalid: false, formErrors: {} });
      },
      hideForm() {
        this.set({ isShowForm: false });
      },
      handleInputKeyDown(event) {
        if(event.code === 'Enter') {
          event.preventDefault()
          this.submit();
        }
      },
      populateInputWith(json) {
        const { saveTo } = this.get();
        const newOption = document.createElement('option');
        newOption.innerText = json.name
        newOption.setAttribute('value', json.id)
        newOption.setAttribute('selected', 'selected');
        saveTo.appendChild(newOption);
        $(saveTo).trigger('chosen:updated');
        this.set(_.cloneDeep(INITIAL_STATE));
      },
      submit() {
        const { form } = this.get();
        fetch('/api/v1/app_assets', { method: 'POST', body: form })
          .then(resp => resp.json())
          .then((json) => {
            if(json.invalid) {
              return this.set({ invalid: true, formErrors: json.errors })
            }
            this.populateInputWith(json);
          });
      }
    }
  }
</script>
