import $ from 'jquery';
import {} from 'jquery-ujs';
import PNotify from 'pnotify';
import 'bootstrap';
import 'trix';

import './zcustomjs';
import './inlined';
import './app';

window.$ = $;
window.jQuery = $;

PNotify.prototype.options.styling = 'bootstrap3';

$(document).ready(() => {
  const railsNotificationMap = {
    alert: 'error',
    notice: 'success',
  };
  window.__DATA.flash.forEach(notification => new PNotify({
    title: 'Notification',
    text: notification.message,
    type: railsNotificationMap[notification.type] || notification.type,
  }));
});
