import { get as storeGet, set as storeSet } from './store';

const COOKIE_KEY = 'DefaultPersonalDataCategories';

function companyIdKey(companyId) {
  return `${COOKIE_KEY}/${companyId}`;
}

export function get(companyId) {
  return storeGet(companyIdKey(companyId));
}

export function set(companyId, value) {
  storeSet(companyIdKey(companyId), value);
}
