<div class="gdpr-central-alert alert alert-error alert-dismissible show">
  <button on:click="close(event)" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <h2>
    Next errors prohibited this form from being saved:
  </h2>
  <ul>
    {#each Object.entries(errors) as [key, message]}
      <li>{ `${key} - ${message}` }</li>
    {/each}
  </ul>
</div>
<script>
  export default {
    methods: {
      close(event) {
        this.fire('close', event);
        this.destroy();
      }
    }
  }
</script>
