import { fetch } from 'whatwg-fetch';

export function apiFetch(url, options = {}) {
  const body = options.body ? JSON.stringify(options.body) : null;
  return fetch(
    url,
    {
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      method: options.method,
      body,
    },
  );
}
