import $ from 'jquery';
import './form.scss';

const VISIBLE_CLASS = 'security-measures-form__note-editor--visible';

$(document).ready(() => {
  $('.security-measures-form__assign-checkbox').change(({ target }) => {
    const $editor = $(target)
      .parents('.security-measures-form__row')
      .find('.security-measures-form__note-editor');

    if (target.checked) {
      $editor.addClass(VISIBLE_CLASS);
    } else {
      $editor.removeClass(VISIBLE_CLASS);
    }
  });
});
