import $ from 'jquery';
import 'chosen-js';
import 'chosen-js/chosen.css';

$(document).ready(() => {
  $('.gdpr-chosen-select').each(function forEach() {
    const $select = $(this);
    const dataWidth = $select.data('width');
    const options = {};
    if(dataWidth) {
      options["width"] = dataWidth;
    }
    options["no_results_text"] = window.I18n.t("no_results_text");
    options["placeholder_text_single"] = window.I18n.t("placeholder_text_single");
    options["placeholder_text_multiple"] = window.I18n.t("placeholder_text_multiple");
    $select.chosen(options);
  });
});
