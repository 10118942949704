import $ from 'jquery';
import _ from 'lodash';

const FORM_STATES = {};

function formValues(form) {
  const $form = $(form);
  const acc = {};
  const $inputs = $form.find('input,select,textarea');
  $inputs.each(function () {
    const $input = $(this);
    const key = $input.attr('name');
    if ($input.attr('type') === 'checkbox') {
      acc[key] = $input.prop('checked');
    } else {
      acc[key] = $input.val();
    }
  });
  return acc;
}

function selectChanged(forms) {
  return forms.filter(function () {
    return !_.isEqual(FORM_STATES[this].initial, formValues(this));
  });
}

$(document).ready(() => {
  const $avoidLossForms = $('form[data-avoid-loss]');
  if ($avoidLossForms.length) {
    $avoidLossForms.each(function () {
      const state = { initial: formValues(this), submitted: false };
      FORM_STATES[this] = state;
    });
    $avoidLossForms.on('submit', (e) => {
      FORM_STATES[e.target].submitted = true;
    });
    window.addEventListener('beforeunload', (e) => {
      const changedForms = selectChanged($avoidLossForms);
      if (changedForms.length) {
        const message = Array.from(changedForms)
          .map(f => (FORM_STATES[f].submitted ? null : f.dataset.avoidLoss))
          .filter(m => !!m)
          .join('\n');
        if (!message) { return null; }
        e.returnValue = message;
        return message;
      }
      return null;
    });
  }
});
