{#if isShowForm}
  <fieldset style="background: white; padding: 10px;">
    {#if invalid}
      <FormErrors on:close="closeErrors()" errors={formErrors} />
    {/if}

    <div class="form-group">
      <label>{ t('name') }</label>
      <input on:keydown="handleInputKeyDown(event)" type="text" class="form-control" name="supplier[name]" bind:value=form.name />
      <small class="form-text text-muted">
        The name of the supplier.
      </small>
    </div>
    <div class="form-group">
      <label>Processing agreement clauses</label>
      <small class="form-text text-muted">
        Select all applicable clauses.
      </small>
      {#each processorAgreementChecklistItems as processorAgreementChecklistItem}
        <div class="form-check">
          <input
            on:keydown="handleInputKeyDown(event)"
            class="form-check-input"
            type="checkbox"
            value={processorAgreementChecklistItem.id}
            name="supplier[processor_agreement_checklist_item_ids][]"
            id={`supplier_processor_agreement_checklist_item_ids_${processorAgreementChecklistItem.id}`}
            bind:checked=form.processor_agreement_checklist_item_ids[processorAgreementChecklistItem.id]
          >
          <label class="form-check-label" for={`supplier_processor_agreement_checklist_item_ids_${processorAgreementChecklistItem.id}`}>
            {processorAgreementChecklistItem.description}
          </label>
        </div>
      {/each}
    </div>
    <div class="form-group">
      <label>{ t('data_breach_notification') }</label>
      <input on:keydown="handleInputKeyDown(event)" type="text" class="form-control" name="supplier[data_breach_notification]" bind:value=form.data_breach_notification />
      <small class="form-text text-muted">
        What is the maximum time it will take the supplier to alert a possible data breach.
      </small>
    </div>

    <div class="form-group">
      <label>{ t('end_of_contract') }</label>
      <input on:keydown="handleInputKeyDown(event)" type="text" class="form-control" name="supplier[end_of_contract]" bind:value=form.end_of_contract />

      <small class="form-text text-muted">
        When would the contract be completed.
      </small>
    </div>

    <div class="form-check">
      <label for="supplier_processor">Is processor:</label>
      <input on:keydown="handleInputKeyDown(event)" class="form-check-input" type="checkbox" name="supplier[processor]" id="supplier_processor" bind:value=form.processor>
      <small id="processorhelp" class="form-text text-muted">
        Unchecking this will suppress the processor requirements (such as the DPA requirements).
      </small>
    </div>

    {#each assetForms as assetForm, i}
      <p>
        <button on:click="removeAssetForm(i)" type="button" class="btn btn-danger btn-small">Remove</button>
      </p>
      <div class="form-group">
        <label>{ t('name') }<sup>*</sup></label>
        <input on:keydown="handleInputKeyDown(event)" type="text" class="form-control" name="supplier[asset][name]" bind:value=assetForm.name />
        <small class="form-text text-muted">
          { t('app_assets.description_name') }
        </small>
      </div>
      <div class="form-group">
        <label>{ t('classification') }</label>
        <select on:keydown="handleInputKeyDown(event)" class="form-control" name="supplier[asset][data_classification_id]" bind:value=assetForm.data_classification_id>
          <option>{ t('please_select') }</option>
          {#each dataClassifications as dataClassification}
            <option value={dataClassification.id}>{ dataClassification.name }</option>
          {/each}
        </select>
        <small class="form-text text-muted">
          { t('app_assets.description_classification') }
        </small>
      </div>
      <div class="form-group">
        <label>{ t('description') }</label>
        <input on:keydown="handleInputKeyDown(event)" type="text" class="form-control" name="supplier[asset][description]" bind:value=assetForm.description />
        <small class="form-text text-muted">
          { t('app_assets.description_description') }
        </small>
      </div>
      <div class="form-group">
        <label>{ t('self_hosted') }</label>
        <select on:keydown="handleInputKeyDown(event)" class="form-control" name="supplier[asset][self_hosted]" bind:value=assetForm.self_hosted>
          <option>{ t('please_select') }</option>
          <option value="yes">{ t('true') }</option>
          <option value="no">{ t('false') }</option>
        </select>
        <small class="form-text text-muted">
          { t('app_assets.description_self_hosted') }
        </small>
      </div>

      <div class="form-group">
        <label>{ t('hosting_country') }</label>
        <select on:keydown="handleInputKeyDown(event)" class="form-control" name="supplier[asset][country_id]" bind:value=assetForm.country_id>
          <option>{ t('please_select') }</option>
          {#each countries as country}
            <option value={country.id}>{ country.name }</option>
          {/each}
        </select>

        <small class="form-text text-muted">
          { t('app_assets_description_hosting_country') }
        </small>
      </div>
      <div class="form-group">
        <label>{ t('mechanism_for_international_data_exhange') }</label>
        <input on:keydown="handleInputKeyDown(event)" type="text" class="form-control" name="supplier[asset][mechanism_international_data_exchange]" bind:value=assetForm.mechanism_international_data_exchange/>

        <small class="form-text text-muted">
          { t('app_assets.description_international_exchange_mechanism') }
        </small>
      </div>
    {/each}
    <button type="button" class="btn btn-success" on:click="addAssetForm()">{ t('add_asset') }</button>

    <div class="actions">
      <button type="button" class="btn btn-primary" on:click="submit()">{ t('save') }</button>
      <button type="button" class="btn btn-primary" on:click="hideForm()">Cancel</button>
    </div>
  </fieldset>
{:else}
  <button type="button" class="btn btn-primary" on:click="showForm()">Add supplier</button>
{/if}

<script>
  import { apiFetch as fetch } from '../../../services/apiFetch';
  import _ from 'lodash';
  import $ from 'jquery';

  const INITIAL_STATE = {
    isShowForm: false,
    form: {
      name: '',
      processor_agreement_checklist_item_ids: {},
      data_breach_notification: '',
      end_of_contract: ''
    },
    formErrors: {},
    invalid: false,
    assetForms: []
  };

  const ASSET_FORM_TEMPLATE = {
    name: '',
    description: '',
    mechanism_international_data_exchange: ''
  };

  export default {
    data() {
      return _.cloneDeep(INITIAL_STATE);
    },
    components: {
      FormErrors: '../../common/FormErrors',
    },
    helpers: {
      t(k) {
        return window.I18n.t(k);
      },
    },
    methods: {
      showForm() {
        this.set({ isShowForm: true });
      },
      closeErrors() {
        this.set({ invalid: false, formErrors: {} });
      },
      hideForm() {
        this.set({ isShowForm: false });
      },
      handleInputKeyDown(event) {
        if(event.code === 'Enter') {
          event.preventDefault()
          this.submit();
        }
      },
      removeAssetForm(index) {
        const { assetForms } = this.get();
        const result = [...assetForms.slice(0, index), ...assetForms.slice(index + 1)];
        this.set({ assetForms: result });
      },
      addAssetForm() {
        const { assetForms } = this.get();

        this.set({ assetForms: [...assetForms, _.cloneDeep(ASSET_FORM_TEMPLATE)] });
      },
      populateInputWith(json) {
        const { saveTo } = this.get();
        const newOption = document.createElement('option');
        newOption.innerText = json.name
        newOption.setAttribute('value', json.id)
        newOption.setAttribute('selected', 'selected');
        saveTo.appendChild(newOption);
        $(saveTo).trigger('chosen:updated');
        this.set(_.cloneDeep(INITIAL_STATE));
      },
      submit() {
        const { form, assetForms } = this.get();
        Object.entries(form.processor_agreement_checklist_item_ids).filter(([id, v]) => v).map(([id]) => id)

        const body = {
          supplier: Object.assign(
            {}, form,
            {
              processor_agreement_checklist_item_ids: Object.entries(
                form.processor_agreement_checklist_item_ids
              ).filter(([id, v]) => v).map(([id]) => id),
              assets: assetForms.reduce((acc, form, index) => {
                acc[index] = form;
                return acc;
              }, {}),
            }
          ),
        };

        fetch('/api/v1/suppliers', { method: 'POST', body })
          .then(resp => resp.json())
          .then((json) => {
            if(json.invalid) {
              return this.set({ invalid: true, formErrors: json.errors })
            }
            this.populateInputWith(json);
          });
      }
    }
  }
</script>
