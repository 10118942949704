import $ from 'jquery';
import _ from 'lodash';
import { CURRENT_URL } from '../../constants';

function calcLinkMatch(link) {
  const urlParts = CURRENT_URL.split('/');
  const hrefParts = link.href.split('/');
  // NOTE: start with -3 first 3 parts are host which we don't care of
  let res = -3;
  urlParts.every((part, i) => {
    if (part !== hrefParts[i]) {
      return false;
    }
    res += 1;
    return true;
  });
  return res;
}

$(document).ready(() => {
  const $sidebarLinks = $('#sidebar-menu').find('a');
  if (!$sidebarLinks.length) { return; }

  const currentPageLink = _.maxBy($sidebarLinks, calcLinkMatch);
  // NOTE: check if at least one match exists
  if (calcLinkMatch(currentPageLink) > 0) {
    $(currentPageLink)
      .parent('li')
      .addClass('current-page')
      .parents('ul')
      .slideDown(() => {
        // reset height
        $('.right_col').css('min-height', $(window).height());

        const bodyHeight = $('body').outerHeight();

        const footerHeight = $('body').hasClass('footer_fixed')
          ? -10
          : $('footer').height();

        const leftColHeight = $('.left_col')
          .eq(1)
          .height() + $('.sidebar-footer').height();

        let contentHeight = bodyHeight < leftColHeight ? leftColHeight : bodyHeight;

        // normalize content
        contentHeight -= $('.nav_menu').height() + footerHeight;

        $('.right_col').css('min-height', contentHeight);
      })
      .parent()
      .addClass('active');
  }
});
