<tr class="row-start" class:removed="leftPersonalDataCategory && !rightPersonalDataCategory" class:added="!leftPersonalDataCategory && rightPersonalDataCategory">
  <td colspan="3">{leftPersonalDataCategory ? leftPersonalDataCategory.name : rightPersonalDataCategory.name}</td>
</tr>
<tr>
  <CompareValue label={t('data_classification') + ":"} left={leftPersonalDataCategory} right={rightPersonalDataCategory} renderProperty={p => p.data_classification ? p.data_classification.name : "None"} />
</tr>
<tr>
  <CompareValue label={t('legal_bases') + ":"} left={leftPersonalDataCategory} right={rightPersonalDataCategory} renderProperty={p => p.legal_bases && p.legal_bases.map(lb => lb.name).join(', ')} />
</tr>
<tr>
  <CompareValue label={t('special_category_legal_base') + ":"} left={leftPersonalDataCategory} right={rightPersonalDataCategory} renderProperty={p => p.special_legal_base && p.special_legal_base.name} />
</tr>
<tr>
  <CompareValue label={t('additional_information') + ":"} left={leftPersonalDataCategory} right={rightPersonalDataCategory} renderProperty={p => p.description} />
</tr>
<tr>
  <CompareValue label={t('stored_processed_on') + ":"} left={leftPersonalDataCategory} right={rightPersonalDataCategory} renderProperty={p => p.assets && p.assets.map(a => a.name).join(', ')} />
</tr>
<tr>
  <CompareValue label={t('stored_for') + ":"} left={leftPersonalDataCategory} right={rightPersonalDataCategory} renderProperty={p => p.retention_time && p.retention_time.display_value} />
</tr>
<tr></tr>

<script>
  export default {
    components: {
      CompareValue: './CompareValue'
    },
    helpers: {
      t(k) {
        return window.I18n.t(k);
      },
    },

  }
</script>

<style>
  .row-start {
    border-top: 2px solid;
  }
  .removed {
    background: #fdaeb7;
    text-decoration: line-through;
  }

  .added {
    background: #bef5cb;
  }
</style>
