<tbody>
  <tr>
    <CompareValue label={t('name') + ":"} left={versionLeft} right={versionRight} renderProperty={p => p.name} />
  </tr>
  <tr>
    <CompareValue label={t('register') + ":"} left={versionLeft} right={versionRight} renderProperty={p => p.registry && p.registry.name} />
  </tr>
  <tr>
    <CompareValue label={t('status') + ":"} left={versionLeft} right={versionRight} renderProperty={
      (p) => {
        if (p.approved) {
          return `Approved by ${ p.approval.user } on ${ p.approval.created_at }.`;
        } else {
          return [
            p.status,
            p.shortcomings && p.shortcomings.length && `<ul>${p.shortcomings.map(s => `<li>${s}</li>`) }</ul>`
          ].join();
        }
      }
    } />
  </tr>
  <tr>
    <CompareValue label={t('responsible') + ":"} left={versionLeft} right={versionRight} renderProperty={p => p.person && p.person.name} />
  </tr>
  <tr>
    <CompareValue label={t('purposes') + ":"} left={versionLeft} right={versionRight} renderProperty={p => p.final_objectives.map(fo => fo.description).join(', ')} />
  </tr>
  <tr>
    <CompareValue label={t('description') + ":"} left={versionLeft} right={versionRight} renderProperty={p => p.description} />
  </tr>
  <tr>
    <CompareValue label={t('source') + ":"} left={versionLeft} right={versionRight} renderProperty={p => p.source} />
  </tr>
  <tr>
    <CompareValue label={t('data_subject_categories') + ":"} left={versionLeft} right={versionRight} renderProperty={p => p.data_subject_categories.map(fo => fo.name).join(', ')} />
  </tr>

  <tr>
    <CompareValue label={t('rights_of_the_data_subject') + ":"} left={versionLeft} right={versionRight} renderProperty={p => p.informed_direct_data} />
  </tr>
  <tr>
    <CompareValue label={t('informed_indirect_data') + ":"} left={versionLeft} right={versionRight} renderProperty={p => p.informed_indirect_data} />
  </tr>
  <tr>
    <CompareValue label={t('exercise_rights') + ":"} left={versionLeft} right={versionRight} renderProperty={p => p.exercise_rights} />
  </tr>
  <tr>
    <CompareValue
      label={t('other_receivers') + ":"}
      left={versionLeft}
      right={versionRight}
      renderProperty={(p) => {
        return p.receiver_instances.map(ri => `${ri.receiver.name} - ${ri.detail}`).join(', ')
      }}
    />
  </tr>

  <tr>
    <td colspan="3"><h2>{t('personal_data') + ':'}</h2></td>
  </tr>

  {#each zippedPersonalDataCategories as [leftPersonalDataCategory, rightPersonalDataCategory]}
    <ComparePersonalDataCategories {leftPersonalDataCategory} {rightPersonalDataCategory} />
  {/each}
</tbody>

<script>
  import _ from 'lodash';

  export default {
    components: {
      ComparePersonalDataCategories: './ComparePersonalDataCategories',
      CompareValue: './CompareValue',
    },
    helpers: {
      t(k) {
        return window.I18n.t(k);
      },
    },
    computed: {
      zippedPersonalDataCategories: ({ versionLeft, versionRight }) => {
        let result =
          versionLeft.personal_data_categories
          .reduce((acc, x) => {
            acc[x.id] = [x];
            return acc;
          }, {});

        result =
          versionRight.personal_data_categories
          .reduce((acc, x) => {
            acc[x.id] = acc[x.id] || [];
            acc[x.id][1] = x;
            return acc;
          }, result);

        return _.values(result);
      }
    }
  };
</script>
