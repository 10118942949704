// NOTE: update V#{} prefix in case of schema updates
const PREFIX = 'GDPR-Central-V1';
const storage = window.localStorage;

function convertKey(key) { return `${PREFIX}/${key}`; }

export function set(key, value) {
  storage.setItem(convertKey(key), JSON.stringify(value));
}

export function get(key) {
  return JSON.parse(storage.getItem(convertKey(key)));
}
