import $ from 'jquery';
import _ from 'lodash';
import 'jquery-ui/ui/widgets/autocomplete';
import './autocomplete.scss';

$(document).ready(() => {
  const $inputWrappers = $('.gdpr-autocomplete');
  if (!$inputWrappers.length) { return; }

  $inputWrappers.each(function eachWrapper() {
    const $inputWrapper = $(this);
    const $input = $inputWrapper.find('.gdpr-autocomplete__input');
    const $noResults = $inputWrapper.find('.gdpr-autocomplete__no-results');
    const $fields = Array.from($('.gdpr-autocomplete__hidden-field')).reduce((acc, f) => {
      const $f = $(f);
      acc[$f.data('autocomplete-field')] = $f;
      return acc;
    }, {});

    const source = $input.data('autocomplete-options');
    const currentValue = $input.data('autocomplete-value');
    const currentItem = source.find(i => _.isEqual(i.value, currentValue));
    if (currentItem) { $input.val(currentItem.label); }
    $input.autocomplete({
      source,
      minLength: 0,
      select(e, ui) {
        e.preventDefault();
        if (!ui.item) { return; }
        this.value = ui.item.label;
        Object.entries(ui.item.value).forEach(([field, value]) => {
          $fields[field].val(value);
        });
      },
      response(e, ui) {
        if (ui.content.length === 0) { $noResults.show(); }
      },
      search: () => { $noResults.hide(); },
    }).focus(function focusExpand() { $(this).autocomplete('search'); });
  });
});
