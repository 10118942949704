import $ from 'jquery';
import List from 'list.js';

$(document).click((e) => {
  if (
    !$(e.target).is('.notification_list_cover')
    && !$(e.target).is('.notification_wrapper a')
  ) {
    if (
      $('.notification_wrapper').hasClass('open')
      && !$('.notification_wrapper').hasClass('opened')
    ) {
      $('.notification_wrapper').addClass('opened');
    } else if ($('.notification_wrapper').hasClass('opened')) {
      $('.notification_wrapper')
        .removeClass('open')
        .removeClass('opened');
    }
  }
});

$('.dropdown_notification').click(function dropdownNotificationClick() {
  $(this)
    .parent()
    .toggleClass('open');
});

(function loadSubscription() {
  $('.field_wrapper.subscribing')
    .find("input[type='checkbox']")
    .change(function inputChange() {
      const $thisFieldWrapper = $(this)
        .parent()
        .parent()
        .parent()
        .parent();
      if ($(this).prop('checked')) {
        $thisFieldWrapper.next().slideDown();
        $thisFieldWrapper
          .next()
          .find("input[type='checkbox']")
          .prop('checked', window.__DATA.activityNotificationSubscribe);
        $thisFieldWrapper
          .next()
          .next()
          .slideDown();
        $thisFieldWrapper
          .next()
          .next()
          .find("input[type='checkbox']")
          .prop('checked', window.__DATA.activityNotificationSubscribe);
      } else {
        $thisFieldWrapper.next().slideUp();
        $thisFieldWrapper
          .next()
          .next()
          .slideUp();
        setTimeout(() => {
          $thisFieldWrapper
            .next()
            .find("input[type='checkbox']")
            .prop('checked', false);
          $thisFieldWrapper
            .next()
            .next()
            .find("input[type='checkbox']")
            .prop('checked', false);
        }, 400);
      }
    });
}());

const options = {
  valueNames: ['name', 'tags', 'person', 'description', 'register', 'status', 'address', 'company_number', 'supplier'],
  page: 25,
  pagination: true,
};

// eslint-disable-next-line no-new
new List('entries', options);

$(document).ready(() => {
  $('.thread_hide_link').click(() => {
    $('.thread_span').hide();

    $('.thread_show')
      .fadeIn()[0]
      .scrollIntoView();
  });

  $('.thread_show_link').click(() => {
    $('.thread_span')
      .fadeIn()[0]
      .scrollIntoView();

    $('.thread_show').hide();
  });

  $('.thread_hide').show();
});

$(".right-of-access-checkbox-listener").on("change", function() {
    console.log(this.id);
    $("." + this.id).parent().toggle();
    //$(this).toggle();
});

$(".questionnaire_template_questions__checkbox").on("change", function() {
    $(".questionnaire_template_questions__additional_fields").toggle();
});
