{#if isShowForm}
  <fieldset style="background: white; padding: 10px;">
    {#if invalid}
      <FormErrors on:close="closeErrors()" errors={formErrors} />
    {/if}
    <div class="form-group">
      <label>{ t('name') }<sup>*</sup></label>
      <input on:keydown="handleInputKeyDown(event)" type="text" class="form-control" bind:value=form.name />
    </div>

    <div class="actions">
      <button type="button" class="btn btn-primary" on:click="submit()">{ t('save') }</button>
      <button type="button" class="btn btn-primary" on:click="hideForm()">{ t('cancel') }</button>
    </div>
  </fieldset>
{:else}
  <button type="button" class="btn btn-primary" on:click="showForm()">{ t('add_access_individual') }</button>
{/if}

<script>
  import { apiFetch as fetch } from '../../../services/apiFetch';
  import _ from 'lodash';
  import $ from 'jquery';

  const INITIAL_STATE = {
    isShowForm: false,
    form: {
      name: '',
      company_id: ''
    },
    formErrors: {},
    invalid: false
  };

  export default {
    data() {
      return _.cloneDeep(INITIAL_STATE)
    },
    components: {
      FormErrors: '../../common/FormErrors',
    },
    helpers: {
      t(k) {
        return window.I18n.t(k)
      },
    },
    methods: {
      showForm() {
        this.set({ isShowForm: true })
      },
      closeErrors() {
        this.set({ invalid: false, formErrors: {} })
      },
      hideForm() {
        this.set({ isShowForm: false })
      },
      handleInputKeyDown(event) {
        if(event.code === 'Enter') {
          event.preventDefault()
          this.submit()
        }
      },
      populateInputWith(json) {
        const { saveTo } = this.get()

        const newDiv = document.createElement('div')
        const newInput = document.createElement('input')
        const newLabel = document.createElement('label')

        const id = 'registry_entry_access_individual_ids_' + json.id

        newDiv.setAttribute('class', 'form-check')

        newLabel.innerText = json.name
        newLabel.setAttribute('for', id)
        newLabel.setAttribute('class', 'form-check-label')

        newInput.setAttribute('id', id)
        newInput.setAttribute('value', json.id)
        newInput.setAttribute('checked', true)
        newInput.setAttribute('type', 'checkbox')
        newInput.setAttribute('name', 'registry_entry[access_individual_ids][]')
        newInput.setAttribute('class', 'form-check-input')

        newDiv.appendChild(newInput)
        newDiv.appendChild(newLabel)

        saveTo.appendChild(newDiv)
        // Appending these things renders differently from the originals
        // This is due to the original html containing some random whitespaces, causing html to render a space between the checkbox & the label
        // I don't know how to replicate this, so it's going to look like shit for a while.

        this.set(_.cloneDeep(INITIAL_STATE))
      },
      submit() {
        const { form } = this.get()
        form.company_id = this.get().companyId
        fetch('/api/v1/access_individual', { method: 'POST', body: form })
          .then(resp => resp.json())
          .then((json) => {
            if(json.invalid) {
              return this.set({ invalid: true, formErrors: json.errors })
            }
            this.populateInputWith(json)
          })
      }
    }
  }
</script>
