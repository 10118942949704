import $ from 'jquery';
import _ from 'lodash';
import { get as defaultGet, set as defaultSet } from '../../services/defaultPersonalDataCategoriesStore';
import './form.scss';

const BALANCING_TEST = 'personal-data-categories__balancing-test';
const MAIN_FIELDS = 'personal-data-categories__main-fields';
const DEFAULT_FIELD_DATA_ATTR = 'defaults-key';
const COMPANY_ID_REGEXP = /^\/companies\/([^/]+)/i;

const storeFieldsetAsDefault = _.debounce((companyId, $fieldset) => {
  defaultSet(companyId, getValuesFor($fieldset));
}, 100);

function visibleClassFor(klass) {
  return klass + '--visible';
}

function setMainFieldsVisibility(checkbox, $fieldset) {
  const $mainFields = $fieldset.find(`.${MAIN_FIELDS}`);
  const visibleClass = visibleClassFor(MAIN_FIELDS);

  if (checkbox.checked) {
    $mainFields.addClass(visibleClass);
  } else {
    $mainFields.removeClass(visibleClass);
  }
}

function fieldsetFor(el) {
  return $(el).parents('fieldset');
}

function populateWithDefaults(companyId, $fieldset) {
  const defaults = defaultGet(companyId);
  if (!defaults) { return; }
  Object.entries(defaults).forEach(([key, value]) => {
    const $input = $fieldset.find(`[data-${DEFAULT_FIELD_DATA_ATTR}='${key}']`);
    if ($input.attr('type') === 'checkbox') {
      $input.prop('checked', value);
    } else {
      $input.val(value);
    }
    if ($input.hasClass('gdpr-chosen-select')) {
      $input.trigger('chosen:updated');
      $input.trigger('change');
    }
  });
}

function getValuesFor($fieldset) {
  const $inputs = $fieldset.find(`[data-${DEFAULT_FIELD_DATA_ATTR}]`);
  const acc = {};
  $inputs.each(function () {
    const $input = $(this);
    const key = $input.data(DEFAULT_FIELD_DATA_ATTR);
    if ($input.attr('type') === 'checkbox') {
      acc[key] = $input.prop('checked');
    } else {
      acc[key] = $input.val();
    }
  });
  return acc;
}

$(document).ready(() => {
  const matchResult = document.location.pathname.match(COMPANY_ID_REGEXP);
  if (!matchResult) { return; }
  const companyId = matchResult[1];


  $('.personal-data-categories__legal-base-select').change((e) => {
    console.log("change in legal base detected");
    const $select = $(e.target);
    const hasBalancingTest = !!$select.find('option:selected[data-has-balancing-test]').length;
    const $balancingTest = fieldsetFor($select).find(`.${BALANCING_TEST}`);
    const visibleClass = visibleClassFor(BALANCING_TEST);



if (hasBalancingTest) {
      $balancingTest.addClass(visibleClass);
    } else {
      $balancingTest.removeClass(visibleClass);
    }
  });

  const $checkboxes = $('.personal-data-categories__selected-checkbox');

  const defaults = defaultGet(companyId);
  const $checked = $checkboxes.filter(':checked');
  if (!defaults && $checked.length) {
    defaultSet(companyId, getValuesFor(fieldsetFor($checked[0])));
  }

  $(`[data-${DEFAULT_FIELD_DATA_ATTR}]`).change((e) => {
    const $fieldset = fieldsetFor(e.target);
    storeFieldsetAsDefault(companyId, $fieldset);
  });

  const checkboxesInitialState = {};
  $checkboxes.each(function () {
    setMainFieldsVisibility(this, fieldsetFor(this));
    checkboxesInitialState[this] = this.checked;
  });

  $checkboxes.change((e) => {
    const checkbox = e.target;
    const $fieldset = fieldsetFor(checkbox);

    setMainFieldsVisibility(checkbox, $fieldset);
    if (!checkboxesInitialState[checkbox] && checkbox.checked) {
      populateWithDefaults(companyId, $fieldset);
    }
  });
});
