<canvas ref:canvas width="400" height="200"></canvas>

<script>
  import Chart from 'chart.js';
  import { DateTime } from 'luxon'

  export default {
    oncreate() {
      const { dates, security_issues_counts, incomplete_registry_entries_counts, shortcomings_counts } = this.get();
      const formatedDates = dates.map(d => DateTime.fromJSDate(new Date(d)).toFormat('dd-MM-yyyy'));

      const ctx = this.refs.canvas.getContext('2d');
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: formatedDates,
          datasets: [{
            label: 'security issues',
            data: security_issues_counts,
            backgroundColor: 'rgba(255, 0, 0, 0.1)',
            borderColor: 'rgba(255, 0, 0, 0.5)'
          }, {
            label: 'incomplete registry entries',
            data: incomplete_registry_entries_counts,
            backgroundColor: 'rgba(0, 255, 0, 0.1)',
            borderColor: 'rgba(0, 255, 0, 0.5)'
          }, {
            label: 'open actions',
            data: shortcomings_counts,
            backgroundColor: 'rgba(0, 0, 255, 0.1)',
            borderColor: 'rgba(0, 0, 255, 0.5)',
          }]
        },
      });
    }
  }
</script>
