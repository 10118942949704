import $ from 'jquery';

(function attachDebouncer(jq, sr) {
  // debouncing function from John Hann
  // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
  function debounce(func, threshold, execAsap) {
    let timeout;

    return function debounceCallback(...args) {
      const obj = this;
      function delayed() {
        if (!execAsap) func.apply(obj, args);
        timeout = null;
      }

      if (timeout) clearTimeout(timeout);
      else if (execAsap) func.apply(obj, args);

      timeout = setTimeout(delayed, threshold || 100);
    };
  }

  // smartresize
  // eslint-disable-next-line no-param-reassign
  jq.fn[sr] = function attached(fn) {
    return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr);
  };
}($, 'smartresize'));
/**
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

// Sidebar
$(document).ready(() => {
  // TODO: This is some kind of easy fix, maybe we can improve this

  $('#sidebar-menu')
    .find('a')
    .on('click', function onSidebarClick() {
      const $li = $(this).parent();

      if ($li.is('.active')) {
        $li.removeClass('active active-sm');
        $('ul:first', $li).slideUp(() => {
          $('.right_col').css('min-height', $(window).height());

          const bodyHeight = $('body').outerHeight();

          const footerHeight = $('body').hasClass('footer_fixed')
            ? -10
            : $('footer').height();

          const leftColHeight = $('.left_col')
            .eq(1)
            .height() + $('.sidebar-footer').height();


          let contentHeight = bodyHeight < leftColHeight ? leftColHeight : bodyHeight;

          // normalize content
          contentHeight -= $('.nav_menu').height() + footerHeight;

          $('.right_col').css('min-height', contentHeight);
        });
      } else {
        // prevent closing menu if we are on child menu
        if (!$li.parent().is('.child_menu')) {
          $('#sidebar-menu')
            .find('li')
            .removeClass('active active-sm');
          $('#sidebar-menu')
            .find('li ul')
            .slideUp();
        }

        $li.addClass('active');

        $('ul:first', $li).slideDown(() => {
          $('.right_col').css('min-height', $(window).height());

          const bodyHeight = $('body').outerHeight();


          const footerHeight = $('body').hasClass('footer_fixed')
            ? -10
            : $('footer').height();


          const leftColHeight = $('.left_col')
            .eq(1)
            .height() + $('.sidebar-footer').height();


          let contentHeight = bodyHeight < leftColHeight ? leftColHeight : bodyHeight;

          // normalize content
          contentHeight -= $('.nav_menu').height() + footerHeight;

          $('.right_col').css('min-height', contentHeight);
        });
      }
    });

  // toggle small or large menu
  $('#menu_toggle').on('click', () => {
    if ($('body').hasClass('nav-md')) {
      $('#sidebar-menu')
        .find('li.active ul')
        .hide();
      $('#sidebar-menu')
        .find('li.active')
        .addClass('active-sm')
        .removeClass('active');
    } else {
      $('#sidebar-menu')
        .find('li.active-sm ul')
        .show();
      $('#sidebar-menu')
        .find('li.active-sm')
        .addClass('active')
        .removeClass('active-sm');
    }

    $('body').toggleClass('nav-md nav-sm');

    // reset height
    $('.right_col').css('min-height', $(window).height());

    const bodyHeight = $('body').outerHeight();


    const footerHeight = $('body').hasClass('footer_fixed')
      ? -10
      : $('footer').height();


    const leftColHeight = $('.left_col')
      .eq(1)
      .height() + $('.sidebar-footer').height();


    let contentHeight = bodyHeight < leftColHeight ? leftColHeight : bodyHeight;

    // normalize content
    contentHeight -= $('.nav_menu').height() + footerHeight;

    $('.right_col').css('min-height', contentHeight);
  });

  // recompute content when resizing
  $(window).smartresize(() => {
    // reset height
    $('.right_col').css('min-height', $(window).height());

    const bodyHeight = $('body').outerHeight();


    const footerHeight = $('body').hasClass('footer_fixed')
      ? -10
      : $('footer').height();


    const leftColHeight = $('.left_col')
      .eq(1)
      .height() + $('.sidebar-footer').height();


    let contentHeight = bodyHeight < leftColHeight ? leftColHeight : bodyHeight;

    // normalize content
    contentHeight -= $('.nav_menu').height() + footerHeight;

    $('.right_col').css('min-height', contentHeight);
  });

  // reset height
  $('.right_col').css('min-height', $(window).height());

  const bodyHeight = $('body').outerHeight();


  const footerHeight = $('body').hasClass('footer_fixed')
    ? -10
    : $('footer').height();


  const leftColHeight = $('.left_col')
    .eq(1)
    .height() + $('.sidebar-footer').height();


  let contentHeight = bodyHeight < leftColHeight ? leftColHeight : bodyHeight;

  // normalize content
  contentHeight -= $('.nav_menu').height() + footerHeight;

  $('.right_col').css('min-height', contentHeight);

  // fixed sidebar
  if ($.fn.mCustomScrollbar) {
    $('.menu_fixed').mCustomScrollbar({
      autoHideScrollbar: true,
      theme: 'minimal',
      mouseWheel: { preventDefault: true },
    });
  }
});
// /Sidebar

let checkState = '';

// Table
function onIfChecked() {
  checkState = '';
  $(this)
    .parent()
    .parent()
    .parent()
    .addClass('selected');
  countChecked();
}
function onIfUnchecked() {
  checkState = '';
  $(this)
    .parent()
    .parent()
    .parent()
    .removeClass('selected');
  countChecked();
}

$('table input').on('ifChecked', onIfChecked);
$('table input').on('ifUnchecked', onIfUnchecked);
$('.bulk_action input').on('ifChecked', onIfChecked);
$('.bulk_action input').on('ifUnchecked', onIfUnchecked);

$('.bulk_action input#check-all').on('ifChecked', () => {
  checkState = 'all';
  countChecked();
});
$('.bulk_action input#check-all').on('ifUnchecked', () => {
  checkState = 'none';
  countChecked();
});

function countChecked() {
  if (checkState === 'all') {
    $(".bulk_action input[name='table_records']").iCheck('check');
  }
  if (checkState === 'none') {
    $(".bulk_action input[name='table_records']").iCheck('uncheck');
  }

  const checkCount = $(".bulk_action input[name='table_records']:checked").length;

  if (checkCount) {
    $('.column-title').hide();
    $('.bulk-actions').show();
    $('.action-cnt').html(checkCount + ' Records Selected');
  } else {
    $('.column-title').show();
    $('.bulk-actions').hide();
  }
}

// Accordion
$(document).ready(() => {
  $('.expand').on('click', function onExpandClick() {
    $(this)
      .next()
      .slideToggle(200);
    const $expand = $(this).find('>:first-child');

    if ($expand.text() === '+') {
      $expand.text('-');
    } else {
      $expand.text('+');
    }
  });
});

