{#each diff as part}
  {#if !part.removed && !part.added || (reverse ? part.removed : part.added)}
    <span class:removed="part.removed" class:added="part.added">{part.value}</span>
  {/if}
{/each}

<style>
  .removed {
    background: #fdaeb7;
    text-decoration: line-through;
  }

  .added {
    background: #bef5cb;
  }
</style>
