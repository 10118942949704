import CompareApp from './compare_app/CompareApp.html';

const target = document.getElementById('compare-app');
if (target) {
  // eslint-disable-next-line no-new
  new CompareApp({
    target,
    data: {
      versions: JSON.parse(target.dataset.versions),
    },
  });
}
